// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lsf_collapse__8x9rr {
    margin: 1%;
    overflow: hidden;
    transition: height 0.2s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/ui/pages/container/Label-Studio/lsf.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;IAChB,mCAAmC;AACvC","sourcesContent":[".collapse {\n    margin: 1%;\n    overflow: hidden;\n    transition: height 0.2s ease-in-out;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapse": `lsf_collapse__8x9rr`
};
export default ___CSS_LOADER_EXPORT___;
