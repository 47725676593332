// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  /* margin: 0; */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: lightgray; */
  margin: 0;
}

html,
body {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.full-page-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(0.5, 0, 0, 0.5);  
  z-index: 1000;
  width: 100%;
  height:100%;
  top:0;
  left:0;
  opacity: 0.4;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf;;iBAEe;EACf,mCAAmC;EACnC,kCAAkC;EAClC,iCAAiC;EACjC,SAAS;AACX;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE;aACW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,sCAAsC;EACtC,aAAa;EACb,WAAW;EACX,WAAW;EACX,KAAK;EACL,MAAM;EACN,YAAY;AACd","sourcesContent":["body {\n  /* margin: 0; */\n  /* font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif; */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  /* background-color: lightgray; */\n  margin: 0;\n}\n\nhtml,\nbody {\n  height: 100%;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n.full-page-loader {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  background-color: rgba(0.5, 0, 0, 0.5);  \n  z-index: 1000;\n  width: 100%;\n  height:100%;\n  top:0;\n  left:0;\n  opacity: 0.4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
