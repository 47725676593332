
import { Grid, Link, Typography, Hidden,ThemeProvider, Box, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { translate } from "../../../../config/localisation";
import LoginAPI from "../../../../redux/actions/api/UserManagement/Login";
import LoginStyle from "../../../styles/loginStyle";
// import Button from "../../component/common/Button";
import CustomCard from "../../component/common/Card";
import OutlinedTextField from "../../component/common/OutlinedTextField";
import themeDefault from '../../../theme/theme'
import OnBoardingForm from '../../component/common/OnBoardingForm'


export default function AppInfo() {
    let navigate = useNavigate();
    const classes = LoginStyle();
    const routeChange = () =>{ 
        let path = `dashboard`; 
        navigate(path);
      }
    const [openOnboardingForm, setOpenOnboardingForm] = useState(false);
  return (
    <div>
        <ThemeProvider theme={themeDefault}>
    
    <Grid container>
      
    <Hidden only="xs">
   <Grid item xs={10} sm={10} md={10} lg={10} xl={10}  >
   
        <img src={"Shoonya_Logo.png"} alt="logo" style={{width:"85px",margin: "10% 0px 0% 35px",borderRadius:"50%"}} />      </Grid> </Hidden>
        
         <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
         <Typography  variant={"h2"} className={classes.title}  style={{ margin: "10% 294px 10% 39px"}} onClick={routeChange} >{process.env.REACT_APP_PROJECT_NAME}</Typography>
            </Grid>
         <Hidden only="xs">
         <Typography variant={"body1"} className={classes.body} style={{ margin: "20px 0px 50px 39px",}}>
         {translate("label.shoonyaInfo")}
         </Typography>
         </Hidden>
         <Button
            variant="contained"
            color="secondary"
            sx={{
              margin: "auto",
              width: "80%",
              background: "white",
              color: "rgba(44, 39, 153, 1)",
            }}
            onClick={() => setOpenOnboardingForm(true)}
          >
            Request to Join
         </Button>
       
    
  </Grid>
    {openOnboardingForm && (
            <OnBoardingForm
              openOnboardingForm={openOnboardingForm}
              handleClose={() => setOpenOnboardingForm(false)}
            />
          )
    }
  </ThemeProvider>


    </div>
  )
}
