export default {
  interfaces: [
    "panel",
    "update",
    "submit",
    "skip",
    "controls",
    "infobar",
    "topbar",
    "instruction",
    "side-column",
    "annotations:history",
    "annotations:tabs",
    "annotations:menu",
    "annotations:current",
    "annotations:add-new",
    "annotations:delete",
    'annotations:view-all',
    "predictions:tabs",
    "predictions:menu",
    "auto-annotation",
    "edit-history",
  ],
};
