const localisation = {
  landlingPageLabel: "Shoonya by AI4Bharat",
  enterEmailId: "Enter your Email ID*",
  enterPassword: "Enter your Password*",
  forgotPassword: "Forgot Password?",
  newPassword:"Enter your New Password*",
  currentPassword:"Enter your Current Password*",
  "label.shoonyaInfo":
    `${process.env.REACT_APP_PROJECT_NAME} is an open source platform to annotate and label data at scale, built with a vision to enhance digital presence of under-represented languages in India.`,
    "button.clearAll" : "Clear All",
    "button.clear" : "Clear",
    "button.Filter" : "Filter",
    "button.Apply" : "Apply",
    "label.filter.status" : "Status",
    "label.filter.byAnnotator" : "Filter by Annotators",
    "label.filter.column": "Select Columns",
    "button.cancel" : "Cancel",
    "button.addNewWorkspace" : "Add New Workspace",
    "button.inviteNewMEmbersToOrganization":"Invite new members to organization",
    "button.notes":"Notes",
    "button.draft":"Draft",
    "button.next":"Next",
    "button.skip":"Skip",
    "button.submit":"Submit",
    "label.workspaces":"Workspaces",
    "label.members":"Members",
    "label.annotators":"Annotators",
    "label.reviewers":"Reviewers",
    "label.invites":"Invites",
    "label.settings":"Settings",
    "label.logs":"Logs",
    "label.tasks":"Tasks",
    "label.annotationTasks":"Annotation Tasks",
    "label.reviewTasks":"Review Tasks",
    "label.allTask ":"All Tasks",
    "label.reports":"Reports",
    "label.projects":"Projects",
    "label.annotators":"Annotators",
    "label.managers":"Managers",
    "label.backToPreviousPage":"Back To Previous Page",
    "label.showProjectSettings":"Show Project Settings",
    "label.backToProject": "Back To Project",
    "alert.notes": "Please do not add notes if you are going to skip the task!",
    "label.datasets": "Datasets",
    "button.downloadDataset": "Download Dataset",
    "button.uploadData":"Upload Data",
    "link.Createanaccount":"Create an account",
    "tooltip.AnalyticsType":" The variation in chart with respect to the number of selected time ranges. In 'Individual', chart for a single time range is displayed. In 'Comparison', two different charts can be compared based on the time ranges selected. Default selection is 'Individual'.",
    "tooltip.ProjectType":" The type of the project for which the chart should be based on. Default selection is 'ContextualTranslationEditing'.",
    "tooltip.Baseperiod":"The time range selected for the first plot of the chart. Currently it can be cumulative, monthly or weekly. The default selection is 'Cumalative'.",
    "tooltip.ComparisonPeriod":"The time range selected for the second plot of the chart. This is applicable when the analytics type is Comparison. Currently it can be cumulative, monthly or weekly.",
    "label.recentTasks.annotation": "Annotations",
    "label.recentTasks.review": "Reviews",
    "button.deleteDataItems":"Delete Data Item",
    "label.Rate this translation":"Rate this translation",
    "button.Suggest an edit":"Suggest an edit",
    "button.Your feedback":"Your feedback will be used to help improve the product",
    "label.analytics": "Analytics",
  
};

export const translate = (prop) => localisation[prop];
