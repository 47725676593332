import constants from "../../constants";

let initialState = {
    data: []
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.UPDATE_UI_PREFS:
            return {
                ...state,
                data: action.payload
            }

        default:
            return {
                ...state
            }
    }

};

export default reducer;
