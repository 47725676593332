export const domains = [
  {"label": "General", "code": "general"},
  {"label": "News", "code": "news"},
  {"label": "Education", "code": "education"},
  {"label": "Legal", "code": "legal"},
  {"label": "Government Press Release", "code": "government-press-release"},
  {"label": "Healthcare", "code": "healthcare"},
  {"label": "Agriculture", "code": "agriculture"},
  {"label": "Automobile", "code": "automobile"},
  {"label": "Tourism", "code": "tourism"},
  {"label": "Financial", "code": "financial"},
  {"label": "Movies", "code": "movies"},
  {"label": "Subtitles", "code": "subtitles"},
  {"label": "Sports", "code": "sports"},
  {"label": "Technology", "code": "technology"},
  {"label": "Lifestyle", "code": "lifestyle"},
  {"label": "Entertainment", "code": "entertainment"},
  {"label": "Art and Culture", "code": "art-and-culture"},
  {"label": "Parliamentary", "code": "parliamentary"},
  {"label": "Economy", "code": "economy"},
  {"label": "History", "code": "history"},
  {"label": "Philosophy", "code": "philosophy"},
  {"label": "Religion", "code": "religion"},
  {"label": "National Security and Defence", "code": "national-security-and-defence"},
  {"label": "Literature", "code": "literature"},
  {"label": "Geography", "code": "geography"}
]

export const orgTypeList = [
  {"label": "NGO", "code": "NGO"},
  {"label": "Startup", "code": "Startup"},
  {"label": "Corporate", "code": "Corporate"}
]